<template>
  <!--begin::Tabs-->
  <v-tabs v-model="tabIndex" class="mb-4">
    <v-tab>Domain</v-tab>
    <v-tab>Renewal Payments</v-tab>

    <v-tab-item class="m-4">
      <!-- Tab 1: Domain Link Form -->
      <div>
        <h4 class="mb-10 font-weight-bold text-dark">
          Link your domain <br />
          <small>You can link your domain to your website in this section.</small>
        </h4>
        <form
          class="form"
          novalidate="novalidate"
          id="domain_form"
          ref="form"
          @submit.prevent="save"
        >
          <div class="form-group">
            <label>Domain</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="custom_domain"
              placeholder="Enter Your domain"
              v-model="form.custom_domain"
              ref="custom_domain"
            />
            <span class="form-text text-muted"
              >Please enter your domain e.g abc.com</span
            >
          </div>

          <div class="d-flex flex-row-reverse" v-if="form.domain_verify == 'not_verified'">
            <button
              ref="form_submit"
              class="btn btn-primary mt-3 right"
              @click="save"
            >
              Save
            </button>
          </div>
        </form>

        <div v-if="form.domain_verify == 'pending'">
          <p>Complete the following steps and click verify.</p>
          <p> Add an "A" record entry for "@" points to "18.135.160.181" in the DNS.</p>
          <div class="d-flex flex-row-reverse">
            <button
            v-if=" $hasPermission('manage system settings') "

              ref="form_submit"
              class="btn btn-primary mt-3 right"
              @click="verify"
            >
              Verify
            </button>
          </div>
        </div>

        <div v-if="form.domain_verify == 'verified'">
          <p>You are using {{ form.custom_domain }} as your primary domain.</p>
        </div>
      </div>
    </v-tab-item>

    <v-tab-item class="m-4">
      <DomainPayments></DomainPayments>
    </v-tab-item>
  </v-tabs>
  <!--end::Tabs-->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import DomainPayments from "./DomainPayments.vue";
export default {
  name: "Domain",
  components:{
    DomainPayments
  },
  data() {
    return {
      payments: [], // Existing payments data
      tabIndex: 0, // Default to the first tab (Domain)
      form: {
        custom_domain: '',
        domain_verify: 0
      },
      isValid: false,
      loading: false, // Loading state for payment processing
      selectedPaymentMethod: null, // Selected payment method for custom payment
      paymentMethods: [], // List of saved payment methods
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.get();
  //  this.fetchPaymentMethods(); // Fetch payment methods on component mount
  },
  methods: {


    get() {
      ApiService.get("business/settings", "domain")
        .then(({ data }) => {
          this.form = data.data;
          this.payments = data.data.domain_renewal_payments;
        })
        .catch(({ response }) => {
          console.error("Error fetching domain data:", response.data.errors);
        });
    },
    save() {
      if (!this.form.custom_domain) {
        Swal.fire("Error!", "Please enter domain name", "error");
        return;
      }
      let currentUserId = this.currentUser.id;

      ApiService.post("business/settings/domain/store/" + currentUserId, this.form)
        .then(({ data }) => {
          Swal.fire("Success", "Domain added", "success");
          this.form = data.data;
        })
        .catch(({ response }) => {
          Swal.fire("Error", response.data.message || "An error occurred.", "error");
        });
    },
    verify() {
      let currentUserId = this.currentUser.id;
      ApiService.post(`business/settings/domain/verify/${currentUserId}`, this.form)
        .then(({ data }) => {
          this.form = data.data;
          if (data.data.domain_verify === "verified") {
            Swal.fire("Success", "Domain verified successfully", "success");
          } else {
            Swal.fire("Error", "Domain Not Verified", "error");
          }
        })
        .catch(({ response }) => {
          let errorMessage = response?.data?.message || "An error occurred while verifying the domain.";
          Swal.fire("Error", errorMessage, "error");
        });
    },
    async fetchPaymentMethods() {
      try {
        const { data } = await ApiService.get("business/settings/payment-methods");
        this.paymentMethods = data;
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    },
    async initiateCustomPayment() {
      this.loading = true;

      try {
        // Ensure a payment method is selected
        if (!this.selectedPaymentMethod) {
          Swal.fire("Error", "Please select a payment method.", "error");
          return;
        }

        // Process payment using Stripe
        const response = await ApiService.post("business/settings/custom-payment", {
          amount: 15, // £15 in cents
          payment_method: this.selectedPaymentMethod,
        });

        if (response.data.success) {
          Swal.fire("Success", "Payment of £15 was successful!", "success");
          // Refresh payments list
          this.get();
        } else {
          Swal.fire("Error", "Payment failed. Please try again.", "error");
        }
      } catch (error) {
        console.error("Payment Error:", error);
        Swal.fire("Error", "An error occurred while processing the payment.", "error");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  text-align: start;
}

.pac-target-input {
  padding: 10px;
  width: 20%;
}

.btn {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: greenyellow;
}
</style>